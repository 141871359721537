
import {ref, watch} from "vue";
import {ContentLoader} from 'vue-content-loader';
import {mecanicoView} from "@/services/CrudService";

export default {
  name: "visualizarMecanicoModal",

  props: {
    mecanico: {
      type: Object,
      required: true,
    }
  },

  components: {
    ContentLoader
  },

  setup(props) {
    let animate = ref(true);

    watch(() => props.mecanico, () => {
      listaView(props.mecanico?.codMecanico);
    })

    const mecanicoModal: any = ref({});

    async function listaView(codMecanico) {
      animate.value = true;
      let response = await mecanicoView(codMecanico);
      mecanicoModal.value = response;
      animate.value = false;
    }

    function tratamentoExibicaoQuadro(cod){ 
      if(cod == 0) return 'Apenas acampa'
      if(cod == 2) return 'Apenas quadro'
      if(cod == 1) return 'Acampa e quadro'
    }


    return {
      tratamentoExibicaoQuadro,
      animate,
      listaView,
      mecanicoModal
    }
  }
}
